.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.body-panel{
  width: 100%;
}
.tn-navBar{
  width: 250px;
}

.footer-panel{
    position: absolute;
    width: 100%;
    bottom: 1px;
    height: 40px;
    padding-top: 5px;
    padding-left: 25px;
    padding-right: 25px;
    text-align: center;
    align-content:center;
    background-color: rgb(240, 240, 240);
    position: fixed;


}
.footer-panel-text{
  text-align: center;
  width: 100%;
}

/* .AppTile{
  width: 200px;
  height: 200px;
  min-width: 200px;
  min-height: 200px;
  margin: 25px;
} */

.AppTileGrid{

  margin: 0 auto;
  align-items: center;
}


.tile1 {
  background: #28ABE3;
}

.tile2 {
  background: #0ECEAB;
}

.tile3 {
  background: #FB6648;
}

.tile4 {
  background: #72BF48;
}

.tile5 {
  background: #FA6900;
}

.tile6 {
  background: #0ECEAB;
}

.tile7 {
  background: #FB6648;
}

.tile8 {
  background: #9B59B6;
}

.tile9 {
  background: #28ABE3;
}

.tile10 {
  background: #EACF46;
}

.tilecaption {
  position: relative;
  top: 80%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  margin: 5 !important;
  text-align: center;
  color: white;
  font-family: Segoe UI;
  font-weight: lighter;
}


@media screen and (min-width : 60px) {
  .icontext {
      color: white;
      font-size: 14px;
  }
  .tilecaption-text{
    font-size: 14px;
    font-weight: bold;
  }

  .icontextdark {
      color: black;
      font-size: 14px;
      margin-bottom: 5px;
  }
  .tile {
      cursor: pointer;
      max-width: 120px;
      width: 120px;
      height: 120px;
      margin: 8px;
  }
  .icon-size-normal{
    font-size: 16px !important;
  }
  .icon-size-lg{
    font-size: 20px !important;
  }
  .icon-size-xl{
    font-size: 24px !important;
  }
  .icon-size-xxl{
    font-size: 36px !important;
  }
  .icon-size-xxxl{
    font-size: 48px !important;
  }
}

@media screen and (min-width : 640px) {
  .icontext {
      color: white;
      font-size: 20px;
      margin-bottom: 7px;
  }
  .tilecaption-text{
    font-size: 18px;
    font-weight: bold;
  }
  .icontextdark {
      color: black;
      font-size: 20px;
  }
  .tile {
      cursor: pointer;
      max-width: 150px;
      width: 150px;
      height: 150px;
      margin: 25px;
  }
  .icon-size-normal{
    font-size: 24px !important;
  }
  .icon-size-lg{
    font-size: 30px !important;
  }
  .icon-size-xl{
    font-size: 36px !important;
  }
  .icon-size-xxl{
    font-size: 48px !important;
  }
  .icon-size-xxxl{
    font-size: 60px !important;
  }
}
@media screen and (min-width : 1600px) {
  .icontext {
      color: white;
      font-size: 24px;
      margin-bottom:10px;
  }
  .tilecaption-text{
    font-size: 24px;
    font-weight: bold;
  }

  .icontextdark {
      color: black;
      font-size: 24px;
  }

  .tile {
      cursor: pointer;
      max-width: 200px;
      width: 200px;
      height: 200px;
      margin: 40px;
  }
  .icon-size-normal{
    font-size: 32px !important;
  }
  .icon-size-lg{
    font-size: 40px !important;
  }
  .icon-size-xl{
    font-size: 48px !important;
  }
  .icon-size-xxl{
    font-size: 60px !important;
  }
  .icon-size-xxxl{
    font-size: 72px !important;
  }
}

.faicon{
  width: 80%;
  height: 80%;
}

.color-white{
  color: white;
}

.color-black{
  color: black;
}

.tileLink{
  text-decoration: none;
  cursor: pointer;
}

.tn-sidenav-expanded{
  width: 250px;
  margin-left: 0px;
  padding-left: 15px;
  background-color: #F7F7F7;
}
.tn-sidenav-collapsed{
  width: 40px;
  margin-left: 0px;
  padding-left: 15px;
}


.tn-sidenav-groupheader{
  color:darkslategrey;
  text-align: left;
  /* margin-left: 10px; */
  margin-left: 0px;
  padding-left: 10px;
  height: 100%;
}
.tn-sidenav-tabpicker-expanded{
  /* position: absolute; */
  bottom: 40px;
  width: 239px;
  margin-left: 11px;
  position: fixed;

}
.tn-sidenav-tabpicker-collapsed{
  /* position: absolute; */
  bottom: 40px;
  width: 220px;
  visibility: hidden;
  position: fixed;

}
.tn-sidenav-link{
  text-align: left;
  /* margin-left: 45px; */
  margin-left: 0px;
  padding-left: 45px;
}

/***************************************************************************************************************************/
/****************************************************** CARD ***************************************************************/
/***************************************************************************************************************************/
.tn-main-grid{
  width: 100%;
}
.tn-card{
  width: calc(100% - 60px);
  /* border-width: 1px;
  border-style: solid; */
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  color: #323130;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  /* margin: 0; */
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
  background: #fff;
  padding: 10px;
  margin-bottom: 28px;
  border-radius: 2px;
  background-clip: padding-box;
  box-shadow: 0 1.6px 3.6px 0 rgba(0,0,0,.132),0 .3px .9px 0 rgba(0,0,0,.108);
  position: relative;
  transition: transform .3s cubic-bezier(.1,.9,.2,1),opacity .3s cubic-bezier(.1,.9,.2,1) 50ms,-webkit-transform .3s cubic-bezier(.1,.9,.2,1);
  opacity: 1;
  transform: translate3d(0,0,0);
  transition-delay: 0s;

  margin-bottom: 45px;
  margin-left: 60px;
}
.tn-card-header{
  width: 100%;
  height: 45px;
  vertical-align: middle;
  /* border-width: 1px;
  border-color: black;
  border-style: solid; */
  color: #929191;
  

}
.tn-card-header-icon{
  color: lightgray;
  margin-left: 10px;
  margin-right: 25px;
  vertical-align: middle;

}

@media screen and (min-width : 1100px) {
  .tn-card-header-title{
    line-height: 35px;
    font-size: x-large;
    margin-left: 25px;
    width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media screen and (max-width : 1100px) {
  .tn-card-header-title{
    line-height: 35px;
    font-size: medium;
    margin-left: 25px;
    width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;

  }
}
.tn-card-header-buttonbar{
  /*align-items:flex-end;*/
  
}

.tn-card-body{
  width: 100%;
  /*height: calc(100%-20);*/
  min-height: 800px;
  text-align: left;
}

.tn-tab{
  width: 100%;
  
}
.tn-tab-container{
  width: 100%;
}
.tn-tab-item{
  width: 100%;
}

.tn-column{
   width: 100%; /* TODO*/
}

.tn-row{
  width: 100%;
}
.tn-item{
  width: 100%;
}


.tn-section{
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  color: #323130;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  margin: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
  background: #fff;
  padding-left: 28px;
  padding-right: 28px;
  padding-bottom: 28px;
  padding-top: 4px;
  margin-bottom: 28px;
  border-radius: 2px;
  background-clip: padding-box;
  box-shadow: 0 1.6px 3.6px 0 rgba(0,0,0,.132),0 .3px .9px 0 rgba(0,0,0,.108);
  position: relative;
  transition: transform .3s cubic-bezier(.1,.9,.2,1),opacity .3s cubic-bezier(.1,.9,.2,1) 50ms,-webkit-transform .3s cubic-bezier(.1,.9,.2,1);
  opacity: 1;
  transform: translate3d(0,0,0);
  transition-delay: 0s;
}


@media screen and (min-width : 320px) {
  .tn-flexgrid-commandbar-whitespace{
    width: 0%;
    min-width: 0%;
  }
  .tn-flexgrid-commandbar-menu{
    width: 100%;
    min-width: 100%;
  }

  .tn-card-header-text{
    width: 75%;
    min-width: 75%;
    text-align: left;
  }
  .tn-card-header-menu{
    width: 25%;
    min-width: 25%;
  }
}
@media screen and (min-width : 480px) {
  .tn-flexgrid-commandbar-whitespace{
    width: 0%;
    min-width: 0%;
  }
  .tn-flexgrid-commandbar-menu{
    width: 100%;
    min-width: 100%;
  }
  .tn-card-header-text{
    width: 75%;
    min-width: 75%;
    text-align: left;
  }
  .tn-card-header-menu{
    width: 25%;
    min-width: 25%;
  }
}
@media screen and (min-width : 640px) {
  .tn-flexgrid-commandbar-whitespace{
    width: 30%;
    min-width: 30%;
  }
  .tn-flexgrid-commandbar-menu{
    width: 70%;
    min-width: 70%;
  }
  .tn-card-header-text{
    width: 50%;
    min-width: 50%;
    text-align: left;
  }
  .tn-card-header-menu{
    width: 50%;
    min-width: 50%;
  }
}
@media screen and (min-width : 960px) {
  .tn-flexgrid-commandbar-whitespace{
    width: 40%;
    min-width: 40%;
  }
  .tn-flexgrid-commandbar-menu{
    width: 60%;
    min-width: 60%;
  }
  .tn-card-header-text{
    width: 50%;
    min-width: 50%;
    text-align: left;
  }
  .tn-card-header-menu{
    width: 50%;
    min-width: 50%;
  }
}
@media screen and (min-width : 1024px) {
  .tn-flexgrid-commandbar-whitespace{
    width: 50%;
    min-width: 50%;
  }
  .tn-flexgrid-commandbar-menu{
    width: 50%;
    min-width: 50%;
  }
  .tn-card-header-text{
    width: 60%;
    min-width: 60%;
    text-align: left;
  }
  .tn-card-header-menu{
    width: 40%;
    min-width: 40%;
  }
}


@media screen and (min-width : 1280px) {
  .tn-flexgrid-commandbar-whitespace{
    width: 60%;
    min-width: 60%;
  }
  .tn-flexgrid-commandbar-menu{
    width: 40%;
    min-width: 40%;
  }
  .tn-card-header-text{
    width: 75%;
    min-width: 75%;
    text-align: left;
  }
  .tn-card-header-menu{
    width: 25%;
    min-width: 25%;
  }
}




.tn-flexgrid-container{
  width: 100%;
  /*min-width: 480px;*/
}
.tn-flexgrid-commandbar-stack{
  width: 100%;
}
.tn-flexgrid-detaillist{
  width: 100%;
  margin-top: -6px !important;
}
.tn-flexgrid-commandbar{
  width: 100%;
}


.tn-flexgrid-link{
  /* text-decoration: none; */
   color: black;
   cursor: pointer;
}
.tn-flexgrid-link:visited{
   text-decoration: none;
   cursor: pointer;
}

.tn-inputbox-container{
  width: 100%;
}

.tn-inputbox-textinput{
  width: 100%;
}

.tn-card-header-commandbar-panel{
  align-content:flex-end;
  align-items: flex-end;
  text-align: right;
  width:  100%;
}
.tn-card-header-commandbar{
  width: 100%;
}

.tn-card-header-commandbar-panel-button{
  margin-right: 10px;
}

/* .tn-editor-lightbordercontrol{
  border: 0.5px solid #929191;
} */
.ms-Dropdown{
  border: 0.5px solid #929191;
}

.tn-card-body-stack{
  width: 100%;
}

.appTileGridMessageBar{
  width: 100%;
}

.publicapp{
  width: 100%;
  height: 100%;
}

.tndocument-button{
  position: absolute;
  /* position: absolute;
  bottom: 0px;
  right:5px; */
  bottom: 0px;
  right:5px;
}
.tndocument-input{
  width: 100%;
}

.shopLineTextFieldRow2{
  margin-top: 16px !important;
}

.shopLineTextFieldQuantityEdit{
  max-width: 60px;
  min-width: 60px;
}

.shopLineTextFieldAmountEdit{
  max-width: 120px;
}
.showLineRowSpacing{
  margin-top: 15px !important;
}
.shopLineHeaderRow{
  border-bottom: 1px solid #383838 !important;
  margin-top: 2px;
}
.shopLineFooterRow{
  border-top: 1px solid #383838 !important;
  margin-top: 20px;
}

.advanced-lookup-search-button{
  width: 30px !important;
  max-width: 30px !important;
  min-width: 30px !important;
}
.advanced-lookup-combobox{
  width: 100% !important;
}
.advanced-lookup-container{
  width: 100% !important;
}

.tn-ownergrid{
  margin-left: 10px;
}



/*DESIGNERS*/
.tn-entitydesigner-listviewcell{
  height: 35px;
  width: 100%;
  text-align: center;
  align-content: center;
  border: 1px solid black;
}

.tn-section-header{
  border-bottom: 1px solid rgb(161, 159, 157);
  display: flex;
  overflow: hidden;
  position: relative;
  margin-bottom: 25px;
}
.tn-entity-view-designer{
  margin: 25px;
}
.tn-entity-form-designer{
  margin: 25px;
  min-width: 1024;
  width: 90%;
}
.tn-entity-view-designer-viewpanel{
  width: 100%;
  padding-left: 20px;
}
.tn-entity-view-designer-view{
  margin-left: 25px;
  margin-right: 25px;;
}

.tn-entity-view-designer-viewbutton{
  margin-bottom: 15px;
}

.tn-entity-view-designer-propertybutton{
  margin-bottom: 5px;
}


/* Designer Page */
.tn-designer-page{
  margin:25px;
}

/* SectionControl.tsx */
.tn-section-control{
  overflow: hidden;
  width: 100%;
}
.tn-section-control-header{
  font-size: 20px;
  font-weight: 600;
  color: #605e5c;
  border-bottom: 1px solid rgb(161, 159, 157);
}
.tn-section-control-body{
  margin-top: 10px;
  margin-bottom: 5px;
  width: 100%;
}

/* SolutionContentControl */
.tn-solutioncontent-ul{
  list-style-type: none;
}

/* Style the caret/arrow */
.tn-solutioncontent-listitem {
  cursor: pointer;
  user-select: none; /* Prevent text selection */
}

/* Create the caret/arrow with a unicode, and style it */
.tn-solutioncontent-listitem::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

/* Rotate the caret/arrow icon when clicked on (using JavaScript) */
.tn-solutioncontent-listitem-down::before {
  transform: rotate(90deg);
}

.tn-solutioncontent-listitem-active {
  display: block !important;
}

.tn-solutioncontent-listitem-nested {
  display: none;
}
.tn-solutioncontent-entity{
  height: 25px;
  
}
.tn-solutioncontent-node{
  height: 25px;
  
}
.tn-solutioncontent-node-icon{
  margin-right: 15px;
  font-size: large !important;
}


.tn-designersurface-form{
  width: 100%;
  min-height: 600px;
  border: 1px solid black;
  padding: 15px;
}

.tn-designer-rowsurface-row{
  border-bottom: 1px solid rgb(161, 159, 157);
  min-height: 35px;
}

.tn-designer-rowsurface-col{
  border-right: 1px solid rgb(161, 159, 157);
  min-height: 35px;
}

      
.tn-designer-controlsurface{
  width: 100%;
  min-height: 32px;
  background-color :rgb(248, 244, 244) !important; 
}
.tn-editor-properties-title{
  width: 100%;
  font-size: large;
  color: rgb(161, 159, 157);
  border-bottom: 1px solid rgb(161, 159, 157);
}

.tn-2x {
  font-size: 40px;
}
.tn-3x {
  font-size: 60px;
}
.tn-4x {
  font-size: 80px;
}


.tn-dialog-container-viewedit {
  width: 1200px !important;
  max-width: 1400px;
  height: 800px;
  max-height: 1024;
}